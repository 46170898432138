/* eslint-disable react/no-unknown-property */
// TODO Remove linter disable comment once the @three/fiber components like group or mesh
// will be updated to CamelCase or linter will be updated to also recognize lowerCase names
import * as React from "react";

import { Line, useGLTF, useTexture } from "@react-three/drei";
import { ThreeEvent } from "@react-three/fiber";
import * as THREE from "three";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils";
import { type GLTF } from "three-stdlib";

import { convert } from "@volley/physics";
import { TrainerPosition, CourtPoint } from "@volley/physics/dist/models";

import { Sport } from "../context/sport";

import {
    Court3DModels,
    TrainerModelParts,
    player3DModel,
    CourtSurfaceMappings,
    reserveBanner,
    BannerSurfaceUVMapping,
    TrainerRenderType,
    TrainerColors,
    TextureColor,
    CourtTextures,
} from "./constants";
import { localization2visualizer } from "./utils";

const DEFAULT_POSITION = new THREE.Vector3(0, 0, 0);
const DEFAULT_ROTATION = new THREE.Euler(0, 0, 0);

export interface RenderTrainerPosition extends TrainerPosition {
    armAngle: number;
    render: TrainerRenderType;
    launchPosition: CourtPoint;
}

type Size = [
    width?: number | undefined,
    height?: number | undefined,
    widthSegments?: number | undefined,
    heightSegments?: number | undefined,
];

export interface CourtAOI {
    size: Size;
    position: { x: number; y: number; z: number };
    color: string;
    opacity: number;
}

export { CourtAOI as VisualizerAOI };

interface MeshProps {
    scale?: number;
    position?: THREE.Vector3;
    rotation?: THREE.Euler;
    size?: Size;
    opacity?: number;
    color?: string;
    texture?: THREE.Texture;
    render?: THREE.Color;
}

interface TrainerModelProps {
    position: THREE.Vector3;
    rotation: THREE.Euler;
    simConfig: convert.PhysicsModel;
    armAngle: number;
    launchPosition: THREE.Vector3;
    scale: number;
    render: TrainerRenderType;
}

type ArmGLTFResult = GLTF & {
    nodes: {
        Object_1: THREE.Mesh;
    };
    materials: {
        ["black matte"]: THREE.MeshStandardMaterial;
    };
};

type BaseGLTFResult = GLTF & {
    nodes: {
        Object_1001: THREE.Mesh;
        Object_10001: THREE.Mesh;
    };
    materials: {
        ["wheels.002"]: THREE.MeshStandardMaterial;
        ["black matte.002"]: THREE.MeshStandardMaterial;
    };
};

type HeadGLTFResult = GLTF & {
    nodes: {
        head002: THREE.Mesh;
        head001: THREE.Mesh;
    };
    materials: {
        ["black matte"]: THREE.MeshStandardMaterial;
        ["green frame"]: THREE.MeshStandardMaterial;
    };
};

type ThrowerGLTFResult = GLTF & {
    nodes: {
        Object_1: THREE.Mesh;
    };
    materials: {
        ["black matte"]: THREE.MeshStandardMaterial;
    };
};

export function TrainerModelMulti(
    props: JSX.IntrinsicElements["group"] & TrainerModelProps,
) {
    const { nodes: armNodes, materials: armMaterials } = useGLTF(
        TrainerModelParts.ARM,
    ) as ArmGLTFResult;
    const { nodes: baseNodes, materials: baseMaterials } = useGLTF(
        TrainerModelParts.BASE,
    ) as BaseGLTFResult;
    const { nodes: headNodes, materials: headMaterials } = useGLTF(
        TrainerModelParts.HEAD,
    ) as HeadGLTFResult;
    const { nodes: throwNodes, materials: throwMaterials } = useGLTF(
        TrainerModelParts.THROWER,
    ) as ThrowerGLTFResult;

    const {
        position,
        rotation,
        simConfig,
        armAngle,
        launchPosition,
        scale,
        render,
    } = props;
    const color = TrainerColors[render];

    const { liftPivotPosition, liftArmLength } = simConfig.trainerGeometry;
    const armPosition = React.useMemo(
        () =>
            new THREE.Vector3(
                liftPivotPosition.x,
                liftPivotPosition.y,
                liftPivotPosition.z,
            ),
        [liftPivotPosition],
    );

    const armRotation = React.useMemo(
        () => new THREE.Euler(armAngle, 0, 0),
        [armAngle],
    );
    const headPosition = React.useMemo(
        () =>
            new THREE.Vector3(
                0,
                liftPivotPosition.y + liftArmLength * Math.cos(armAngle),
                liftPivotPosition.z + liftArmLength * Math.sin(armAngle),
            ),
        [armAngle, liftArmLength, liftPivotPosition],
    );

    // NOTE: Need to recalculate launcherPosition because in the group drawing we use trainer's position as origin
    const launcherPosition = React.useMemo(() => {
        const position2D = new THREE.Vector2(position.x, position.y);
        // Head position in 2D with respect to the trainer's origin
        const headPosition2D = new THREE.Vector2(
            position2D.x + headPosition.x,
            position2D.y + headPosition.y,
        );
        const launchPosition2D = new THREE.Vector2(
            launchPosition.x,
            launchPosition.y,
        );
        // Calculate 2D direction of the launcher in respect to trainer origin
        const launcherDir = new THREE.Vector2()
            .subVectors(headPosition2D, position2D)
            .normalize();
        // Calculate distance in 2D space between the trainer origin and launching point
        const distance2D = position2D.distanceTo(launchPosition2D);
        // Calculate 2D launcher position in the Trainer's coordinate space:
        const p2D = launcherDir.multiplyScalar(distance2D);
        // Transform to the 3D space with the correct height
        const p3D = new THREE.Vector3(p2D.x, p2D.y, launchPosition.z);

        return p3D;
    }, [
        position.x,
        position.y,
        launchPosition.x,
        launchPosition.y,
        launchPosition.z,
        headPosition.x,
        headPosition.y,
    ]);

    const renderArmMaterial = React.useMemo(() => {
        const material = new THREE.MeshStandardMaterial();
        material.copy(armMaterials["black matte"]);
        return material;
    }, [armMaterials]);

    const renderBaseMaterial = React.useMemo(() => {
        const material = new THREE.MeshStandardMaterial();
        material.copy(baseMaterials["black matte.002"]);
        return material;
    }, [baseMaterials]);

    const renderWheelMaterial = React.useMemo(() => {
        const material = new THREE.MeshStandardMaterial();
        material.copy(baseMaterials["wheels.002"]);
        return material;
    }, [baseMaterials]);

    const renderHeadBlackMaterial = React.useMemo(() => {
        const material = new THREE.MeshStandardMaterial();
        material.copy(headMaterials["black matte"]);
        return material;
    }, [headMaterials]);

    const renderHeadGreenMaterial = React.useMemo(() => {
        const material = new THREE.MeshStandardMaterial();
        material.copy(headMaterials["green frame"]);
        return material;
    }, [headMaterials]);

    const renderThrowMaterial = React.useMemo(() => {
        const material = new THREE.MeshStandardMaterial();
        material.copy(throwMaterials["black matte"]);
        return material;
    }, [throwMaterials]);

    if (render !== "FULL") {
        renderArmMaterial.transparent = true;
        renderArmMaterial.opacity = 0.6;
        renderArmMaterial.color = color;

        renderBaseMaterial.transparent = true;
        renderBaseMaterial.opacity = 0.6;
        renderBaseMaterial.color = color;

        renderWheelMaterial.transparent = true;
        renderWheelMaterial.opacity = 0.6;
        renderWheelMaterial.color = color;

        renderHeadBlackMaterial.transparent = true;
        renderHeadBlackMaterial.opacity = 0.6;
        renderHeadBlackMaterial.color = color;
        renderHeadGreenMaterial.transparent = true;
        renderHeadGreenMaterial.opacity = 0.6;
        renderHeadGreenMaterial.color = color;

        renderThrowMaterial.transparent = true;
        renderThrowMaterial.opacity = 0.6;
        renderThrowMaterial.color = color;
    }
    return (
        <group
            {...props}
            dispose={null}
            rotation={rotation}
            position={localization2visualizer(position)}
        >
            <mesh
                geometry={armNodes.Object_1.geometry}
                material={renderArmMaterial}
                position={armPosition}
                rotation={armRotation}
                scale={scale}
            />
            <group
                {...props}
                dispose={null}
                position={DEFAULT_POSITION}
                rotation={DEFAULT_ROTATION}
                scale={scale}
            >
                <mesh
                    geometry={baseNodes.Object_1001.geometry}
                    material={renderWheelMaterial}
                />
                <mesh
                    geometry={baseNodes.Object_10001.geometry}
                    material={renderBaseMaterial}
                />
            </group>
            <group
                {...props}
                dispose={null}
                position={headPosition}
                rotation={DEFAULT_ROTATION}
                scale={scale}
            >
                <mesh
                    geometry={headNodes.head002.geometry}
                    material={renderHeadBlackMaterial}
                />
                <mesh
                    geometry={headNodes.head001.geometry}
                    material={renderHeadGreenMaterial}
                />
            </group>
            <group
                {...props}
                dispose={null}
                position={launcherPosition}
                rotation={DEFAULT_ROTATION}
                scale={scale}
            >
                <mesh
                    geometry={throwNodes.Object_1.geometry}
                    material={renderThrowMaterial}
                />
            </group>
        </group>
    );
}

interface YawDiffProps {
    actualYaw: number;
    expectedYaw: number;
    position: THREE.Vector3;
}

export function YawDiff({
    actualYaw,
    expectedYaw,
    position,
}: YawDiffProps): JSX.Element {
    const length = 8;
    const arcRadius = length / 2;

    const trainerPosition = localization2visualizer(position);

    const actualEnd = new THREE.Vector3(
        Math.sin(actualYaw) * length,
        0.1,
        -Math.cos(actualYaw) * length,
    ).add(trainerPosition);

    const expectedEnd = new THREE.Vector3(
        Math.sin(expectedYaw) * length,
        0.1,
        -Math.cos(expectedYaw) * length,
    ).add(trainerPosition);

    const clockwise = actualYaw > expectedYaw;
    const arcCurve = new THREE.EllipseCurve(
        trainerPosition.x,
        trainerPosition.z,
        arcRadius,
        arcRadius,
        actualYaw,
        expectedYaw,
        clockwise,
        -Math.PI / 2,
    );
    const arcPoints = arcCurve
        .getPoints(15)
        .map((point) => new THREE.Vector3(point.x, 0.1, point.y));

    const arrowDirection = new THREE.Vector3()
        .subVectors(expectedEnd, arcPoints[arcPoints.length - 1])
        .normalize();
    const arrowTip = arcPoints[arcPoints.length - 1].clone();
    const arrowRotation = new THREE.Euler(
        0,
        Math.atan2(arrowDirection.x, arrowDirection.z),
        clockwise ? -Math.PI / 2 : Math.PI / 2,
    );

    return (
        <>
            <Line
                points={[trainerPosition, actualEnd]}
                color="#D732B2"
                lineWidth={8}
            />
            <Line
                points={[trainerPosition, expectedEnd]}
                color="#3CE97C"
                lineWidth={8}
            />

            <Line points={arcPoints} color="#132751" lineWidth={8} />

            <mesh position={arrowTip} rotation={arrowRotation}>
                <coneGeometry args={[0.2, 0.5, 8]} />
                <meshBasicMaterial color="#132751" />
            </mesh>
        </>
    );
}

interface ThickArrowProps {
    length: number;
    stemRatio: number;
    direction: THREE.Vector3;
    color: THREE.ColorRepresentation;
}

function Thick2dArrow({
    length,
    stemRatio,
    direction,
    color,
    position,
}: ThickArrowProps & Pick<MeshProps, "position">): JSX.Element {
    const geometry = React.useMemo(() => {
        // Rectangle part is the stemRatio of the entire length
        const rectangleHeight = stemRatio * length;
        const rectangleWidth = rectangleHeight / 8;
        const rectangle = new THREE.PlaneGeometry(
            rectangleWidth,
            rectangleHeight,
            1,
            1,
        );
        rectangle.translate(0, rectangleHeight / 2, 0);

        // Triangle part is rest of the length
        const triangleHeight = length - rectangleHeight;
        const triangleBase = rectangleWidth * 3;
        const triangleShape = new THREE.Shape();
        triangleShape.moveTo(-triangleBase / 2, -triangleHeight);
        triangleShape.lineTo(triangleBase / 2, -triangleHeight);
        triangleShape.lineTo(0, triangleHeight / 2);
        const triangle = new THREE.ShapeGeometry(triangleShape);
        triangle.translate(0, (length + rectangleHeight) / 2, 0);

        // Merge coneGeometry into cylinderGeometry
        return BufferGeometryUtils.mergeGeometries([
            rectangle,
            triangle,
        ]).rotateX(-Math.PI / 2);
    }, [length, stemRatio]);

    const meshRef = React.useRef<THREE.Mesh>(null);
    React.useEffect(() => {
        meshRef.current?.up.set(0, 1, 0);
        meshRef.current?.lookAt(
            meshRef.current.position.clone().add(direction),
        );
    }, [direction]);

    return (
        <mesh
            ref={meshRef}
            geometry={geometry}
            material={
                new THREE.MeshBasicMaterial({
                    color,
                    side: THREE.DoubleSide,
                    depthTest: false,
                    transparent: true,
                })
            }
            position={position}
        />
    );
}

interface DrawArrowProps {
    trainerPositions: RenderTrainerPosition[];
}

// NOTE: Front wheels axle is 31.5 inches(800mm) apart from the rear axle
const frontWheelYOffset = 0.8; // [m]
export function DrawArrow({ trainerPositions }: DrawArrowProps): JSX.Element {
    const { length, direction, position } = React.useMemo(() => {
        let origin = new THREE.Vector3();
        let ghostOrigin = new THREE.Vector3();

        const full = trainerPositions.find((p) => p.render === "FULL");
        if (full) {
            origin = new THREE.Vector3(
                full.x + frontWheelYOffset * Math.sin(full.yaw),
                0.2,
                (full.y + frontWheelYOffset) * -Math.cos(full.yaw),
            );
        }
        const ghost = trainerPositions.find((p) => p.render === "GHOST");
        if (ghost) {
            ghostOrigin = new THREE.Vector3(
                ghost.x + frontWheelYOffset * Math.sin(ghost.yaw),
                0.2,
                (ghost.y + frontWheelYOffset) * -Math.cos(ghost.yaw),
            );
        }

        return {
            length: origin.distanceTo(ghostOrigin),
            direction: new THREE.Vector3()
                .subVectors(origin, ghostOrigin)
                .normalize(),
            position: origin,
        };
    }, [trainerPositions]);

    return (
        <Thick2dArrow
            length={length}
            direction={direction}
            stemRatio={0.8}
            color="red"
            position={position}
        />
    );
}

export function PlayerModel({
    scale,
    position,
    rotation,
}: Pick<MeshProps, "scale" | "position" | "rotation">): JSX.Element {
    const { scene: player3D } = useGLTF(player3DModel);

    return (
        <primitive
            object={player3D}
            position={position}
            rotation={rotation}
            scale={scale}
        />
    );
}

type PlayerGLTFResult = GLTF & {
    nodes: {
        figurearmleft: THREE.Mesh;
        paddlegripinhand: THREE.Mesh;
        paddleracketinhand: THREE.Mesh;
        figureshirtarmleft: THREE.Mesh;
    };
    materials: {
        mat_figure: THREE.MeshStandardMaterial;
        mat_paddle_grip: THREE.MeshStandardMaterial;
        mat_paddle_logo: THREE.MeshStandardMaterial;
        mat_clothes: THREE.MeshStandardMaterial;
    };
};

export function PlayerModelMulti(props: JSX.IntrinsicElements["group"]) {
    const { nodes, materials } = useGLTF(player3DModel) as PlayerGLTFResult;

    return (
        <group {...props} dispose={null}>
            <mesh
                geometry={nodes.figurearmleft.geometry}
                material={materials.mat_figure}
            />
            <mesh
                geometry={nodes.paddlegripinhand.geometry}
                material={materials.mat_paddle_grip}
            />
            <mesh
                geometry={nodes.paddleracketinhand.geometry}
                material={materials.mat_paddle_logo}
            />
            <mesh
                geometry={nodes.figureshirtarmleft.geometry}
                material={materials.mat_clothes}
            />
        </group>
    );
}

export function Ball({
    scale,
    position,
    color,
}: Pick<MeshProps, "position" | "scale" | "color">): JSX.Element {
    return (
        <mesh position={position} scale={scale}>
            <sphereGeometry args={[1, 32, 32]} attach="geometry" />
            <meshBasicMaterial color={color} attach="material" />
        </mesh>
    );
}

export function AOI({
    size,
    position,
    rotation,
    color,
    opacity,
}: Omit<MeshProps, "scale">): JSX.Element {
    return (
        <mesh
            rotation={rotation}
            position={position ? localization2visualizer(position) : undefined}
        >
            <planeGeometry attach="geometry" args={size} />
            <meshBasicMaterial
                attach="material"
                color={color}
                opacity={opacity}
                transparent
            />
        </mesh>
    );
}

function MyPlane({
    size,
    position,
    rotation,
    color,
    texture,
    opacity,
}: Omit<MeshProps, "scale">) {
    return (
        <mesh rotation={rotation} position={position}>
            <planeGeometry attach="geometry" args={size} />
            <meshLambertMaterial
                attach="material"
                color={color}
                map={texture}
                side={THREE.DoubleSide}
                opacity={opacity}
                transparent
            />
        </mesh>
    );
}

export function SimpleCourt({
    sport,
    isReserve,
}: {
    sport: Sport;
    isReserve: boolean;
}): JSX.Element {
    const { courtGeometry } = convert.getPhysicsModel();
    const textureColor = isReserve ? TextureColor.GREEN : TextureColor.DEFAULT;
    const courtTexture = useTexture(
        CourtTextures[sport][textureColor] ?? CourtTextures[sport].DEFAULT,
        (tArg) => {
            const t = tArg;
            // For proper colors rendering when mapping onto the 3D model surface
            // these properties of the texture loaded by THREE.TextureLoader have to be set
            t.colorSpace = THREE.SRGBColorSpace;
            t.flipY = true;
            // Turning off Mipmaps will keep the texture sharp at any camera distance
            t.generateMipmaps = false;
        },
    );

    return (
        <MyPlane
            size={[courtGeometry.PLATFORM_WIDTH, courtGeometry.PLATFORM_LENGTH]}
            texture={courtTexture}
            rotation={new THREE.Euler(-Math.PI / 2, 0, 0)}
        />
    );
}

function mapCourtSurface(
    sport: Sport,
    scene: THREE.Group,
    courtTexture: THREE.Texture,
) {
    const surface = scene.getObjectByName("court-surface") as THREE.Mesh;
    const uvMapping = CourtSurfaceMappings[sport];
    if (surface) {
        surface.geometry.setAttribute("uv", uvMapping);
        if (surface.material instanceof THREE.MeshStandardMaterial) {
            surface.material.metalness = 0;
            surface.material.map = courtTexture;
            surface.material.side = THREE.FrontSide;
        }
    }
}

function mapCourtBanners(scene: THREE.Group, bannerTexture: THREE.Texture) {
    const banner = scene.getObjectByName("banner") as THREE.Mesh;
    if (banner) {
        banner.geometry.setAttribute("uv", BannerSurfaceUVMapping);
        if (banner.material instanceof THREE.MeshStandardMaterial) {
            banner.material.map = bannerTexture;
            banner.material.side = THREE.DoubleSide;
        }
    }
}

interface Court3DModelInterface {
    sport: Sport;
    isReserve: boolean;
    onCoordClick?: (coord: CourtPoint) => void;
}

export function Court3DModel({
    sport,
    onCoordClick,
    isReserve,
}: Court3DModelInterface): JSX.Element {
    const { scene } = useGLTF(Court3DModels[sport]);
    const textureColor = isReserve ? TextureColor.GREEN : TextureColor.DEFAULT;
    const courtTexture = useTexture(
        CourtTextures[sport][textureColor] ?? CourtTextures[sport].DEFAULT,
        (tArg) => {
            const t = tArg;
            // For proper colors rendering when mapping onto the 3D model surface
            // these properties of the texture loaded by THREE.TextureLoader have to be set
            t.colorSpace = THREE.SRGBColorSpace;
            t.flipY = true;
            // Turning off Mipmaps will keep the texture sharp at any camera distance
            t.generateMipmaps = false;
        },
    );
    const bannerTexture = useTexture(reserveBanner, (tArg) => {
        const t = tArg;
        t.colorSpace = THREE.SRGBColorSpace;
        t.generateMipmaps = false;
    });

    React.useEffect(() => {
        if (scene) {
            mapCourtSurface(sport, scene, courtTexture);
        }
    }, [scene, sport, courtTexture]);

    React.useEffect(() => {
        if (sport !== "TENNIS" && scene) {
            if (isReserve) {
                mapCourtBanners(scene, bannerTexture);
            }
        }
    }, [sport, isReserve, scene, bannerTexture]);

    return (
        <primitive
            object={scene}
            position={DEFAULT_POSITION}
            rotation={new THREE.Euler(-Math.PI / 2, 0, 0)}
            onClick={(e: ThreeEvent<React.MouseEvent>) => {
                e.stopPropagation();
                if (onCoordClick) {
                    onCoordClick(e.point);
                }
            }}
        />
    );
}

export function Ground() {
    const groundTexture = React.useMemo(() => {
        const canvas = document.createElement("canvas");
        canvas.width = 512;
        canvas.height = 512;
        const context = canvas.getContext("2d")!;
        context.fillStyle = "rgba(255, 255, 255, 1)";
        context.fillRect(0, 0, canvas.width, canvas.height);
        const canvasTexture = new THREE.CanvasTexture(canvas);
        canvasTexture.mapping = THREE.EquirectangularReflectionMapping;
        return canvasTexture;
    }, []);

    return (
        <mesh rotation={[Math.PI, 0, 0]}>
            {/* args: [radius, widthSegments, heightSegments, phiStart, phiLength, thetaStart, thetaLength] */}
            <sphereGeometry
                args={[35, 32, 32, 0, Math.PI * 2, 0, Math.PI / 2]}
            />
            <meshStandardMaterial
                map={groundTexture}
                metalness={0.3}
                roughness={0.5}
                side={THREE.DoubleSide}
            />
        </mesh>
    );
}
